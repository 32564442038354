import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "../features/api/api";
import { useDispatch } from "react-redux";
import directoryReducer from "../features/directory/directory-slice";
import settingsReducer from "../features/settings/settings-slice";


export const store = configureStore({
    reducer: {
        directory: directoryReducer,
        settings: settingsReducer,
        [apiSlice.reducerPath]: apiSlice.reducer,
    },
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware().concat([
            apiSlice.middleware
        ]);
    }
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;