import { createSlice } from "@reduxjs/toolkit";
import { defaultBaseText } from "./text-interface";
import { isNullOrEmpty } from "../../../global/helpers/data-helpers";


let filtersLabelText = (window as any).bootstrap.filtersLabelText;
let resetFiltersLabelText = (window as any).bootstrap.resetFiltersLabelText;
let sortByLabelText = (window as any).bootstrap.sortByLabelText;
let expandButtonText = (window as any).bootstrap.expandButtonText;
let collapseButtonText = (window as any).bootstrap.collapseButtonText;
let useModal = (window as any).bootstrap.useModal;
let showSearch = (window as any).bootstrap.showSearch;
let sidebarAds = (window as any).bootstrap.sidebarAds;
let hideResultsUntilSearch = (window as any).bootstrap.hideResultsUntilSearch;
let hiddenResultsMessage = !isNullOrEmpty((window as any).bootstrap.hiddenResultsMessage.trim()) ? (window as any).bootstrap.hiddenResultsMessage : defaultBaseText.hiddenResultsMessage;
let noResultsMessage = !isNullOrEmpty((window as any).bootstrap.noResultsMessage.trim()) ? (window as any).bootstrap.noResultsMessage : defaultBaseText.noResultsMessage;
let errorMessage = !isNullOrEmpty((window as any).bootstrap.errorMessage.trim()) ? (window as any).bootstrap.errorMessage : defaultBaseText.errorMessage;
let noFilterText = (window as any).bootstrap.noFilterText;
let showFilterCounts = (window as any).bootstrap.showFilterCounts;
let hideSort = (window as any).bootstrap.hideSort;

const initialState = {
	...defaultBaseText,
	filtersLabelText,
	resetFiltersLabelText,
	sortByLabelText,
	expandButtonText,
	collapseButtonText,
	useModal,
	showSearch,
	sidebarAds,
	hideResultsUntilSearch,
	hiddenResultsMessage,
	noResultsMessage,
	errorMessage,
	noFilterText,
	showFilterCounts,
	hideSort
}

const settingsSlice = createSlice({
	name: 'settings',
	initialState,
	reducers: {
		initializeSettings(state) {
			return {
				...initialState
			}
		}
	}
});

export const { initializeSettings } = settingsSlice.actions;
export default settingsSlice.reducer;