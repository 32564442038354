import { SelectedFacet } from "../../reactComponents/helpers/common-types";
import { isNullOrUndefined } from "../../global/helpers/data-helpers";
import { DirectoryState } from "../features/directory/directory-slice";
import { useAppSelector } from "../hooks/hooks";

export type QueryStringState = {
    skip?: number,
    facets?: SelectedFacet[],
    orderBy?: number,
    direction?: number,
    searchTerm?: string
}

export const getStateFromQueryString = () => {
    const url = window.location.search;
    const urlParams = new URLSearchParams(url);
    let filters: SelectedFacet[] = [];
    let limit = (window as any).bootstrap.pageSize as number;
    urlParams.forEach((value, key) => {
        if (key !== 'page' && key !== 'direction' && key !== 'orderBy' && key !== 'searchTerm') {
            filters.push({ facetName: decodeURIComponent(key), facetValue: decodeURIComponent(value) });
        }
    });

    return {
        skip: urlParams.has('page') ? (parseInt(urlParams.get('page')) - 1)*limit : 0,
        orderBy: urlParams.has('orderBy') ? parseInt(urlParams.get('orderBy')) : null,
        direction: urlParams.has('direction') ? parseInt(urlParams.get('direction')) : null,
        searchTerm: urlParams.has('searchTerm') ? decodeURIComponent(urlParams.get('searchTerm')) : null,
        facets: filters
    } as QueryStringState;
}

export const writeStateToQueryString = ({ apiParams, currentAppliedFilters }: DirectoryState) => {
    const baseUrl = new URL(window.location.origin + window.location.pathname);
    let page = apiParams.skip / apiParams.limit + 1;
    if (page) {
        baseUrl.searchParams.set('page', page.toString());
    }
    if (currentAppliedFilters) {
        currentAppliedFilters.forEach((filter, index) => {
            if (index === 0) {
                baseUrl.searchParams.set(encodeURIComponent(filter.facetName.trim()), encodeURIComponent(filter.facetValue.trim()));
            }
            else {
                baseUrl.searchParams.append(encodeURIComponent(filter.facetName.trim()), encodeURIComponent(filter.facetValue.trim()));
            }
        });
    }

    if (!isNullOrUndefined(apiParams.orderBy)) {
        baseUrl.searchParams.set('orderBy', apiParams.orderBy.toString());
    }

    if (!isNullOrUndefined(apiParams.direction)) {
        baseUrl.searchParams.set('direction', apiParams.direction.toString());
    }

    if (apiParams.searchTerm) {
        baseUrl.searchParams.set('searchTerm', encodeURIComponent(apiParams.searchTerm.trim()));
    }
    window.history.pushState({}, '', baseUrl.toString());
}