import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SelectedFacet } from '../../../reactComponents/helpers/common-types';
import { baseApiParameters } from '../../helpers/common-types';
import { getStateFromQueryString, writeStateToQueryString } from '../../app/query-string';

export interface DirectoryState {
    apiParams: {
        limit: number,
        pageId: number,
        skip: number,
        facets: SelectedFacet[],
        orderBy?: number,
        direction?: number,
        searchTerm?: string
        useKeywordSearch: boolean
    },
    currentAppliedFilters: SelectedFacet[]
}

let limit = (window as any).bootstrap.pageSize as number;
let pageId = (window as any).bootstrap.pageId as number;
const blankFilterState: SelectedFacet[] = [];
const { skip, facets, orderBy, direction, searchTerm } = getStateFromQueryString();
let useKeywordSearch = (window as any).bootstrap.useKeywordSearch as boolean;
// check for query param if page is not set
if (!useKeywordSearch)
{
    const url = window.location.search;
    const urlParams = new URLSearchParams(url);
    if (urlParams.has("usekeywordsearch", "true")) {
        useKeywordSearch = true;
    }
}


const initialState: DirectoryState = {
    apiParams: {
        ...baseApiParameters,
        limit,
        pageId,
        skip,
        facets,
        orderBy,
        direction,
        searchTerm,
        useKeywordSearch
    },
    currentAppliedFilters: facets || blankFilterState,
}

const directorySlice = createSlice({
    name: 'directory',
    initialState,
    reducers: {
        updateSkip(state, action: PayloadAction<{ newSkip: number }>) {
            state.apiParams.skip = action.payload.newSkip;
            writeStateToQueryString(state)
        },
        updateSort(state, action: PayloadAction<{ newColumn: number, newDirection: number }>) {
            state.apiParams.orderBy = action.payload.newColumn;
            state.apiParams.direction = action.payload.newDirection;
            state.apiParams.skip = 0;
            writeStateToQueryString(state);
        },
        updateFilter(state, action: PayloadAction<{ facetName: string, facetValue: string, facetType: string }>) {
            const facetData = {
                facetName: action.payload.facetName,
                facetValue: action.payload.facetValue
            };
            if (action.payload.facetType === "select") {
                let currentFacet = state.currentAppliedFilters.find(filter => filter.facetName === action.payload.facetName);
                if (currentFacet) {
                    currentFacet.facetValue = action.payload.facetValue;
                }
                else (
                    state.currentAppliedFilters.push(facetData)
                )
            }
            else {
                const selectedFacet = state.currentAppliedFilters.find(filter => filter.facetValue === action.payload.facetValue);

                if (selectedFacet) {
                    state.currentAppliedFilters = state.currentAppliedFilters.filter(facet => facet !== selectedFacet);
                }
                else {
                    state.currentAppliedFilters.push(facetData);
                }
            }

            state.apiParams.facets = state.currentAppliedFilters;
            state.apiParams.skip = 0;
            writeStateToQueryString(state)
        },
        resetFilters(state) {
            state.currentAppliedFilters = blankFilterState;
            state.apiParams.facets = blankFilterState;
            state.apiParams.skip = 0;
            state.apiParams.searchTerm = "";
            writeStateToQueryString(state)
        },
        matchStateToQueryString(state) {
            const { skip, facets, orderBy, direction } = getStateFromQueryString();
            state.apiParams.facets = facets || blankFilterState;
            state.apiParams.orderBy = orderBy;
            state.apiParams.direction = direction;
            state.apiParams.skip = skip;
            state.currentAppliedFilters = facets || blankFilterState;
        },
        updateSearchTerm(state, action: PayloadAction<{ searchTerm: string }>) {
            state.apiParams.searchTerm = action.payload.searchTerm || "";
            state.apiParams.skip = 0;
            writeStateToQueryString(state);
        }
    }
})

export const { updateSort, updateSkip, updateFilter, resetFilters, matchStateToQueryString, updateSearchTerm } = directorySlice.actions;
export default directorySlice.reducer;