import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { DirectoryApiParameters, DirectoryResponseData, SortOrderOption } from '../../helpers/common-types';

export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        baseUrl: `/api/v1/`
    }),
    endpoints(builder) {
        return {
            getDirectory: builder.query<DirectoryResponseData, DirectoryApiParameters>({
                query: (body) => {
                    return {
                        url: `directory`,
                        method: 'POST',
                        headers: {
                            "Content-Type": "application/json; charset=utf-8"
                        },
                        body
                    }
                }
            })
        }
    }
});

export const { useGetDirectoryQuery } = apiSlice;