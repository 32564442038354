export interface DirectoryAppBaseText {
    filtersLabelText: string;
    resetFiltersLabelText: string;
    sortByLabelText: string;
    expandButtonText: string;
    collapseButtonText: string;
    useModal: boolean;
    showSearch: boolean;
    sidebarAds: [];
    hideResultsUntilSearch: boolean;
    hiddenResultsMessage: string;
    noResultsMessage: string;
    errorMessage: string;
    noFilterText: string;
    showFilterCounts: boolean;
    hideSort: boolean;
}

export const defaultBaseText: DirectoryAppBaseText = {
    filtersLabelText: 'Filters',
    resetFiltersLabelText: 'Reset Filters',
    sortByLabelText: 'Sort By',
    expandButtonText: 'Expand',
    collapseButtonText: 'Collapse',
    useModal: false,
    showSearch: false,
    sidebarAds: [],
    hideResultsUntilSearch: false,
    hiddenResultsMessage: 'Search to see results',
    noResultsMessage: 'No results found',
    errorMessage: 'An error occurred while getting results',
    noFilterText: 'No filters available',
    showFilterCounts: false,
    hideSort: false
}